<template>
  <v-container
      id="regular-forms"
      fluid
      tag="section"
  >
    <v-row no-gutters>
      <v-col cols="12" md="12">
        <base-material-card
            color="success"
            icon="mdi-lock"
            inline
            :title="baseMaterialCardTitle"
        >
          <template v-if="loading">
            <v-progress-linear
                indeterminate
                color="primary"
            ></v-progress-linear>
            <v-skeleton-loader loading class="mx-auto" type="card"></v-skeleton-loader>
          </template>
          <v-form v-else>
            <v-row no-gutters v-if="!action">
              <v-col cols="11"></v-col>
              <v-col cols="1" class="text-right align-end mr-0">
                <v-btn small right color="info" class="mr-0"
                       @click="$router.push({name: 'Roles', params: {envCode: envSelected.value} })">
                  <v-icon small class="mr-1">
                    mdi-arrow-left
                  </v-icon>
                  Back
                </v-btn>
              </v-col>
            </v-row>
            <v-row class="mt-5" v-if="!loading && hasServices">
              <v-col md="6" sm="12">
                <v-text-field
                    label="Name *"
                    :rules="[() => !!role.name || 'This field is required!']"
                    v-model="role.name"
                    counter
                    required
                />
              </v-col>
              <v-col md="6" sm="12">
                <v-select
                    label="Caching TTL *"
                    v-model="role._TTL"
                    :rules="[() => !!role._TTL || 'This field is required!']"
                    required
                    item-text="text"
                    item-value="value"
                    :items="msCacheOptions"
                ></v-select>
              </v-col>
              <v-col sm="12">
                <v-text-field
                    label="Description"
                    v-model="role.description"
                    counter
                />
              </v-col>
              <v-col md="6" sm="12">

              </v-col>
            </v-row>
            <v-row v-else class="mt-5">
              <v-alert type="warning" outlined border="left" class="mt-5 mx-auto py-3" prominent>
                <h2>Oops!</h2>
                <p>
                  It appears you have no microservices running in this environment yet.<br/>
                  Therefore you cannot create any ACL role.
                </p>
                <p>
                  Head to the <b>API catalog</b> Module under this environment and deploy the M360API Gateway.<br/>
                  Then deploy your microservice and set it to register itself in the Gateway's Cloud Awareness.
                </p>
                <p>
                  Once both steps above are completed, come back to this section and you will be able to create/modify
                  ACL Roles.
                </p>
                <v-btn color="secondary" class="mx-auto float-left" small elevation="3" @click="openDocumentation">
                  <v-icon small color="white" class="mr-1">mdi-information</v-icon>
                  Read the Docks
                </v-btn>
                <v-btn color="primary" class="mx-auto float-right" small elevation="3" @click="goToCatalog">
                  <v-icon small color="white" class="mr-1">mdi-api</v-icon>
                  open API Catalog
                </v-btn>
              </v-alert>
            </v-row>
          </v-form>

        </base-material-card>
      </v-col>
    </v-row>
    <v-row v-if="!loading && hasServices">
      <v-col
          cols="6"
          md="6"
          xs="12"
      >
        <base-material-card
            color="success"
            inline
            text="Enabled Services"
        >

          <v-data-table
              :loading="loading"
              :headers="headers"
              :items="enabledServices"
              disable-pagination
              hide-default-footer
          >

            <template v-slot:item.actions="{ item }">
              <div v-if="fieldAccess.services">
                <router-link
                    v-if="canAccess({method: 'patch', route: `/environments/:env/roles/:id/services/:service`}) && !item.soft"
                    :to="{
                                    name: 'editRolesService',
                                    params: {
                                      id: role.id,
                                      service: item.name,
                                      envCode: envSelected.value,
                                      preview: true
                                    }
                                  }"
                    class="routerLink"
                >
                  <v-btn class="ml-2"
                         x-small
                         fab
                         color="success"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </router-link>
                <v-tooltip v-else top>
                  <template v-slot:activator="{on, attrs}">
                    <v-btn
                        class="ml-2"
                        x-small
                        fab
                        color="info"
                        v-on="on"
                        v-bind="attrs"
                    >
                      <v-icon small>mdi-information</v-icon>
                    </v-btn>
                  </template>
                  <span>You need to Save your changes first, then you can configure this service.</span>
                </v-tooltip>

                <v-btn class="ml-2"
                       x-small
                       fab
                       color="error"
                       @click="removeEnabledService(item)"
                >
                  <v-icon small>mdi-link-off</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>

      <v-col
          cols="6"
          md="6"
          xs="12"
      >
        <base-material-card
            color="success"
            inline
            text="Available Services"
        >

          <v-data-table
              :loading="loading"
              :headers="headers"
              :items="services"
              disable-pagination
              hide-default-footer
          >
            <template v-slot:item.version="{ item }">
              {{ item.version.value }}
            </template>
            <template v-slot:item.actions="{ item }">
              <v-btn v-if="fieldAccess.services"
                     fab
                     x-small
                     @click="addEnabledService(item)"
                     color="secondary"
              >
                <v-icon small>mdi-link</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>

    </v-row>
    <v-row v-if="!loading && hasServices" no-gutters>
      <v-col cols="12" class="text-right align-end">
        <v-btn class="mr-2" color="success" @click="updateRole">
          <v-icon class="mr-1">mdi-content-save</v-icon>
          Save
        </v-btn>
        <v-btn class="mr-0" color="error" @click="initialize">
          <v-icon class="mr-1">mdi-undo</v-icon>
          Reset
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import globalMixins from "@/mixins/globalMixins";
import fieldsMixins from "./components/fieldAccess";

export default {
  name: 'RolesAddEdit',

  props: {
    id: {
      type: String
    },
    envSelected: {
      type: Object
    },
    action: {
      type: String
    },
    code: {
      type: String
    }
  },

  mixins: [globalMixins, fieldsMixins],

  data: () => ({
    fieldAccess: {
      services: true
    },
    loading: false,
    hasServices: false,

    services: [],

    role: {
      name: '',
      description: '',
      _TTL: 0,
      acl: {}
    },

    headers: [
      {
        text: 'Name',
        value: 'name',
      },
      {
        text: 'Version',
        value: 'version',
      },
      {
        align: 'right',
        text: 'Actions',
        value: 'actions',
      },
    ],

    enabledServices: [],

    selectedService: null
  }),

  computed: {
    baseMaterialCardTitle() {
      let label = 'Creating new';
      if (this.id || this.code) {
        label = "Updating";
      }
      if (this.role) {
        return `${label} ACL Role ${this.role.name ? `'${this.role.name}'` : ''}`;
      } else {
        return '';
      }
    }
  },

  async created() {
    this.initialize();
  },

  methods: {

    goToCatalog() {
      this.$router.push({name: 'API Catalog', params: {envSelected: this.envSelected}})
    },

    openDocumentation() {
      window.open(this.$helpLinks.roles);
    },

    initialize() {
      this.clearMessages();
      this.selectedService = null;
      this.getRole(() => {
        this.getServices(() => {
          this.fixServiceRendering();
        });
      });
    },

    getRole(cb) {
      const self = this;
      this.role = {
        name: '',
        description: '',
        _TTL: 0,
        acl: {}
      };
      this.enabledServices = [];
      this.loading = true;

      if ((this.id && this.id.trim() !== '') || (this.code && this.code.trim() !== '')) {
        this.fieldAccess = this.roleUpdateAccess();
        let apiOptions;
        if (this.id) {
          apiOptions = {
            noLoading: true,
            url: `/consoleapi/environments/${this.envSelected.value}/roles/${this.id}`,
            method: "get"
          };
        } else {
          apiOptions = {
            noLoading: true,
            url: `/consoleapi/environments/${this.envSelected.value}/roles/code/${this.code}`,
            method: "get"
          };
        }

        this.getSendData(apiOptions).then((response) => {
          self.role = response.item;
          self.loading = false;
          return cb();
        });
      } else {
        this.fieldAccess = this.roleCreateAccess();
        self.loading = false;
        return cb();
      }
    },

    getServices(cb) {
      const self = this;
      this.loading = true;
      this.services = [];

      const apiOptions = {
        noLoading: true,
        url: `/consoleapi/environments/${this.envSelected.value}/services`,
        method: "get",
        params: {
          'fields': ['name', 'version'],
          'pagination': false
        }
      };

      self.getSendData(apiOptions).then((response) => {
        self.services = response.items || [];
        if (response.items && response.items.length > 0) {
          self.hasServices = true;
        }
        self.loading = false;
        return cb();
      });
    },

    fixServiceRendering() {
      for (let service in this.role.acl[this.envSelected.value.toUpperCase()]) {
        let versions = Object.keys(this.role.acl[this.envSelected.value.toUpperCase()][service]);
        versions.forEach((oneVersion) => {

          let found = false;
          this.enabledServices.forEach((oneFoundService) => {
            if (oneFoundService.name === service && oneFoundService.version === parseInt(oneVersion.replace('v', ''), 10)) {
              found = true;
            }
          });

          if (!found) {
            this.enabledServices.push({name: service, version: parseInt(oneVersion.replace('v', ''), 10)});
          }
        });
      }

      for (let i = this.services.length - 1; i >= 0; i--) {
        for (let k = this.enabledServices.length - 1; k >= 0; k--) {
          if (this.enabledServices[k].name === this.services[i].name
              && this.enabledServices[k].version === this.services[i].version.value) {
            this.services.splice(i, 1);
            break;
          }
        }
      }
    },

    removeEnabledService(item) {
      let esvc = this._lodash.cloneDeep(this.enabledServices);
      for (let i = esvc.length - 1; i >= 0; i--) {
        if (esvc[i].name === item.name && esvc[i].version === item.version) {
          esvc.splice(i, 1);
        }
      }

      this.enabledServices = esvc;
      // delete the Obj
      delete this.role.acl[this.envSelected.value.toUpperCase()][item.name];

      let found = false;
      this.services.forEach((oneService) => {
        if (oneService.name === item.name && oneService.version.value === item.version) {
          found = true;
        }
      });

      if (!found) {
        let allsvc = this._lodash.cloneDeep(this.services);
        allsvc.push({
          name: item.name,
          version: {
            value: item.version
          }
        });
        // available services
        this.services = allsvc;
      }
    },

    addEnabledService(item) {
      let found = true;
      for (let i = this.enabledServices.length - 1; i >= 0; i--) {
        if (this.enabledServices[i].name !== item.name && this.enabledServices[i].version !== 'v' + item.version) {
          found = false;
        }
      }

      if (!found || this.enabledServices.length === 0) {
        let esvc = this._lodash.cloneDeep(this.enabledServices);
        esvc.push({name: item.name, version: item.version.value, soft: true});
        this.enabledServices = esvc;
      }

      let svc = this._lodash.cloneDeep(this.services);
      for (let i = svc.length - 1; i >= 0; i--) {
        if (svc[i].name === item.name && svc[i].version.value === item.version.value) {
          svc.splice(i, 1);
        }
        this.services = svc;
      }
    },

    updateRole() {
      const self = this;
      self.clearMessages();

      if (!this.role.name || this.role.name.trim() === '') {
        this.pushMessage({
          type: 'error',
          title: 'Form Validation',
          text: 'Missing Role name!'
        });
        this.scrollToTop();
        return false;
      }

      if (!this.role.description || this.role.description.trim() === '') {
        this.pushMessage({
          type: 'error',
          title: 'Form Validation',
          text: 'Missing Role description!'
        });
        this.scrollToTop();
        return false;
      }

      if (!this.role._TTL || this.role._TTL === 0) {
        this.pushMessage({
          type: 'error',
          title: 'Form Validation',
          text: 'Missing Role caching TTL value!'
        });
        this.scrollToTop();
        return false;
      }

      if (!this.enabledServices || this.enabledServices.length === 0) {
        this.pushMessage({
          type: 'error',
          title: 'Form Validation',
          text: 'No attached service found!'
        });
        this.scrollToTop();
        return false;
      }

      if (!this.role.acl[this.envSelected.value.toUpperCase()]) {
        this.role.acl[this.envSelected.value.toUpperCase()] = {};
      }

      this.enabledServices.forEach((oneService) => {
        if (!this.role.acl[this.envSelected.value.toUpperCase()][oneService.name]) {
          this.role.acl[this.envSelected.value.toUpperCase()][oneService.name] = {};
        }
        if (!this.role.acl[this.envSelected.value.toUpperCase()][oneService.name]['v' + oneService.version]) {
          this.role.acl[this.envSelected.value.toUpperCase()][oneService.name]['v' + oneService.version] = {
            'apis': {}
          };
        }
      })

      let payload = {
        name: this.role.name,
        description: this.role.description,
        _TTL: this.role._TTL
      };

      if (this.action) {
        this.$emit(this.action, payload);
      } else {
        let apiOptions;

        if (this.role.id) {
          let temp = this._lodash.cloneDeep(self.role.acl[this.envSelected.value.toUpperCase()]);

          if (self.fieldAccess.services) {
            payload.services = {};
            Object.keys(temp).forEach(function (serviceName) {
              let vs = Object.keys(temp[serviceName]);
              payload.services[serviceName] = vs;
            });
          }
          // self.filterFields(payload, self.fieldsAcl.role.update);
          // edit
          apiOptions = {
            url: `/consoleapi/environments/${this.envSelected.value}/roles/${this.role.id}`,
            method: "patch",
            params: payload
          };
        } else {
          payload.acl = {
            [this.envSelected.value.toUpperCase()]: this._lodash.cloneDeep(this.role.acl[this.envSelected.value.toUpperCase()])
          };

          apiOptions = {
            url: `/consoleapi/environments/${this.envSelected.value}/roles`,
            method: "put",
            params: payload
          };
        }

        self.getSendData(apiOptions).then((response) => {

          self.pushMessage({
            type: 'success',
            title: `ACL updated`,
            text: `ACL role has been updated!`
          });
          self.scrollToTop();
          //if add redirect to listing
          if (!self.role.id) {
            setTimeout(() => {
              self.goToPage({route: 'Roles', params: {'envCode': self.envSelected.value}});
            }, 2000);
          } else {
            // refresh
            setTimeout(() => {
              this.initialize();
            }, 2000);
          }

        });
      }
    },

  }
}
</script>

<style scoped>
.routerLink {
  text-decoration: none;
}

</style>
